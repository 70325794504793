import { createEffect } from "solid-js";
import { HOME_HERO_SECTION, PARTNERS_LIST, QUESTIONS_LIST } from "../../const";
import CalloutSection from "./callout";
import DescriptionSection from "./description";
import FAQSection from "./faq";
import HeroSection from "./hero";
import PartnersSection from "./partners";
import { HomeViewProps } from "../../model";
import { ButtonProps } from "src/atoms/buttons/model";
import { Atom } from ":mods";
import { ChatBtn } from "../chat-btn";

export function Home(props: HomeViewProps) {
  const buttons = HOME_HERO_SECTION.buttons;
  buttons.student.link = props.signupLinks.student;
  buttons.tutor.link = props.signupLinks.tutor;

  createEffect(() => {
    // TODO: call the API of the content if it is not a static content
  }, []);
  return (
    <div class="flex flex-col w-full h-full ">
      <HeroSection title={HOME_HERO_SECTION.title} description={HOME_HERO_SECTION.description} buttons={buttons} />
      <DescriptionSection
        contone={"There are no exams to pass and the entire programme is FREE."}
        conttwo={
          "You demonstrate your idea and knowledge by completing a series of challenges that will expose you to what different museums professionals do in their jobs.  Win unique rewards and prizes, whilst learning from leading international experts and gain insights into how they built successful careers within the museum sector."
        }
        contthree={"Sign up now for FREE to instantly unlock the first challenge."}
      />
      <PartnersSection partners={PARTNERS_LIST} />
      <FAQSection questions={QUESTIONS_LIST} />
      <CalloutSection
        title={"Start learning for free and win a in-site museum education course"}
        caption={`“"The environment, course design and outcome is simply brilliant."`}
        person={"Ahmed Karam"}
      />
      <ChatBtn mainPage />
    </div>
  );
}
